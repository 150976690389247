import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button } from "@mui/material";

const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 80 },
    { field: "code", headerName: "代號", width: 120 },
    { field: "name", headerName: "名稱", width: 300 },
    {field:"address",headerName:"地址",width:500},
    { field: "areaName", headerName: "地域", width: 150 },
    { field: "districtName", headerName: "地區", width: 150 }
];

export default function SFIndex() {
    const [locationList, setLocationList] = useState<{ id: string; name: string; areaName: string; districtName: string; }[]>([]);
    async function onClickUpdateSF() {
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/sf-express`, { method: "POST" });
        if (result.status === 200) { };
    };
    useEffect(() => {
        ; (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/sf-express`);
            const json = await result.json();
            setLocationList(json);
        })()
    }, []);
    return (
        <div>
            <DataGrid rows={locationList} columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 50 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                disableRowSelectionOnClick />
            <Button onClick={async () => await onClickUpdateSF()}>更新</Button>
        </div>
    )
}