import { useEffect, useState, forwardRef } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import BillTable from "./Table";
import ModalIndex from "./Modal/Index";

export default function BillIndex() {
    const [filter, setFilter] = useState("待確認");
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [id, setID] = useState("");
    const [list, setList] = useState<{ id: string; customer: string; tel: string; price: number; status: string }[]>([]);
    const ModalComponent = forwardRef(() => <ModalIndex id={id} onClose={() => { setID(""); setUpdate(!update) }} />)
    useEffect(() => {
        ; (async () => {
            setLoading(true);
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/all-bill`);
            const json = await result.json();
            setList(json.result.map((bill: any) => { return { ...bill, date: new Date(bill.date) } }));
            setLoading(false);
        })()
    }, [update]);
    return (
        <div>
            {loading ? <>
                <Stack>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rounded" width={210} height={60} />
                </Stack>
            </> : <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={filter} onChange={(e, value) => setFilter(value)} aria-label="basic tabs example">
                        <Tab label="全部" value="全部" />
                        <Tab label="已取消" value="已取消" />
                        <Tab label="未付款" value="未付款" />
                        <Tab label="待確認" value="待確認" />
                        <Tab label="已確認" value="已確認" />
                        <Tab label="已完成" value="已完成" />
                    </Tabs>
                    <BillTable filter={filter} data={list} selectBill={(id) => setID(id)} />
                </Box>
            </>}
            <Modal open={id !== ""} onClose={() => setID("")}><ModalComponent /></Modal>
        </div>
    )
}