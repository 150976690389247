import { useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from "./hook";

import NavMenuBar from "./navMenuBar/Index";
import ProductIndex from './category/Index';
import BillIndex from './bills/Index';
import PcSetIndex from "./pcSet/Index";
import CarouselIndex from "./carousel/Index";
import BundleIndex from "./bundle/Index";
import CategorySetting from "./categorySetting/Index";
import PromotionIndex from "./promotion/Index";
import CustomerIndex from "./customer/Index";
import AccountIndex from "./account/Index";
import HomeIndex from "./Home/Home";
import PromoCodeIndex from "./promoCode/Index";
import SFIndex from "./sf/Index";
import IndexVideo from "./indexVideo/Index";

export default function LoggedIn() {
  const permissionList = useAppSelector((state) => state.permission);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <NavMenuBar />
        <Box component="main" sx={{
          backgroundColor: (theme) => theme.palette.mode === 'light' ?
            theme.palette.grey[100] : theme.palette.grey[900], flexGrow: 1, height: '100vh', overflow: 'auto',
        }} >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4, marginLeft: 0 }}>
            <Grid container spacing={3}>
              <Routes>
                <Route path="?" element={<HomeIndex />} />
                <Route path="/order" element={<BillIndex />} />
                <Route path="/category/:id" element={<ProductIndex />} />
                <Route path="/pc-set" element={<PcSetIndex />} />
                {permissionList.includes("Carousel") && <Route path="/carousel" element={<CarouselIndex />} />}
                <Route path="/bundle" element={<BundleIndex />} />
                {permissionList.includes("分類設定") && <Route path="/category-setting" element={<CategorySetting />} />}
                {permissionList.includes("Promo Code") && <Route path="/promotion" element={<PromotionIndex />} />}
                {permissionList.includes("顧客") && <Route path="/customer" element={<CustomerIndex />} />}
                {permissionList.includes("員工帳戶") && <Route path="/account" element={<AccountIndex />} />}
                <Route path="/promo-code" element={<PromoCodeIndex />} />
                <Route path="/index-video" element={<IndexVideo />} />
                <Route path="/sf-express" element={<SFIndex />} />
              </Routes>
            </Grid>
          </Container>
        </Box></Box>
    </ThemeProvider>
  );
};