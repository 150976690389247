import { DataGrid, GridColDef } from '@mui/x-data-grid';
const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 110 },
    {
        field: 'customer',
        headerName: '顧客',
        width: 150,
        editable: false,
    },
    {
        field: 'tel',
        headerName: '電話',
        // type: 'number',
        width: 110,
        editable: false,
    },
    {
        field: "price",
        headerName: "價錢",
        type: "number",
        width: 100,
        editable: false,
    },
    {
        field: "status",
        headerName: "狀態",
        type: "string",
        width: 100,
        editable: false,
        sortable: false
    },
    {
        field: "date",
        headerName: "時間",
        type: "dateTime",
        width: 200,
        editable: false,
    }
    // {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params: GridValueGetterParams) =>
    //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
];

export default function BillTable(props: {
    filter: string; data: { id: string; customer: string; tel: string; price: number; status: string; }[];
    selectBill: (id: string) => void;
}) {
    return (
        <DataGrid rows={props.filter !== "全部" ? props.data.filter((data) => data.status === props.filter) : props.data} columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 50 },
                },
            }}
            pageSizeOptions={[5, 10]}
            disableRowSelectionOnClick
            onCellClick={(e) => props.selectBill(e.id + "")}
        />
    )
}