import { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row(props: {
    row: {
        id: string; price: number; payment: string; createdAT: string;
        itemList: { brand: string; name: string; price: number; amount: number; }[];
    }
}) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell>{'$' + row.price}</TableCell>
                <TableCell>{row.payment}</TableCell>
                <TableCell>{new Date(row.createdAT).toLocaleDateString()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {"產品列表"}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{"貨品"}</TableCell>
                                        <TableCell>{"價錢"}</TableCell>
                                        <TableCell>{"數量"}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.itemList.map((item, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">{item.brand + " " + item.name}</TableCell>
                                                <TableCell>{"$" + item.price}</TableCell>
                                                <TableCell>{item.amount}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export default function CustomerModalIndex(props: { id: string; }) {
    const [data, setData] = useState<{
        id: string; name: string; email: string; credit: number;
        billList: {
            id: string; price: number; payment: string; createdAT: string;
            itemList: { brand: string; name: string; price: number; amount: number; }[];
        }[]
    }>({
        id: "", name: "", email: "", credit: 0, billList: []
    });
    useEffect(() => {
        (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/customer/${props.id}`);
            const json = await result.json();
            setData(json.result);
        })()
    }, [props.id]);
    return (
        <Box sx={{
            position: "absolute" as "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: "50px",
            maxWidth: "750px",
            width: "80vw",
            maxHeight: "90vh",
            overflowY: "scroll",
            boxShadow: 24,
            bgcolor: 'background.paper'
        }}>
            <div>{"ID: " + data.id}</div>
            <div>{"姓名: " + data.name}</div>
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead><TableRow>
                            <TableCell /><TableCell>{"ID"}</TableCell>
                            <TableCell>{"價錢"}</TableCell>
                            <TableCell>{"付款方式"}</TableCell>
                            <TableCell>{"建立時間"}</TableCell>
                        </TableRow></TableHead>
                        <TableBody>
                            {data.billList.map((bill, i) => {
                                return (<Row key={i} row={bill} />)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}