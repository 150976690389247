import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AccountModalIndex(props: { id: string; closeModal: () => void; }) {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [allPermissionList, setAllPermissionList] = useState<{ id: number; permission: string; }[]>([]);
    const [selectedPermission, selectPermission] = useState<{ id: number; permission: string; }[]>([]);
    async function submit() {
        if (props.id === "new") {
            ; (async () => {
                const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    }, body: JSON.stringify({ name, username, password, selectedPermission })
                });
                const json = await result.json();
                if (json.result === 'success') { props.closeModal(); };
            })()
        } else { }
    };
    useEffect(() => {
        if (props.id !== "new") {
            ; (async () => {
                const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/staff/${props.id}`);
                const json = await result.json();
                setUsername(json.username);
                selectPermission(json.permissionList);
            })()
        };
        ; (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/staff/all-permission`);
            const json = await result.json();
            setAllPermissionList(json.permissionList);
        })()
    }, [props.id]);
    return (
        <Box sx={{
            position: "absolute" as "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: "50px",
            maxWidth: "750px",
            width: "80vw",
            maxHeight: "90vh",
            overflowY: "scroll",
            boxShadow: 24,
            bgcolor: 'background.paper'
        }}><Box sx={{ '& .MuiTextField-root': { m: 1 }, }}>
                <div><TextField label="Name" variant="standard" value={name} onChange={(e) => setName(e.currentTarget.value)} /></div>
                <div>
                    {props.id === "new" ?
                        <TextField label="username" variant="standard" value={username} onChange={(e) => setUsername(e.currentTarget.value)} /> :
                        <TextField label="username" variant="standard" value={username} InputProps={{ readOnly: true }} />}
                </div>
                <div><TextField label="password" type="password" variant="standard" value={password} onChange={(e) => setPassword(e.currentTarget.value)} /></div>
                <div>
                    <FormLabel component="legend">權限</FormLabel>
                    {allPermissionList.map((permission, i) => {
                        return (
                            <FormControlLabel key={i} control={<Checkbox checked={selectedPermission.some((p) => p.id === permission.id)}
                                onChange={() => {
                                    if (selectedPermission.some((p) => p.id === permission.id)) {
                                        selectPermission(selectedPermission.filter((p) => p.id !== permission.id))
                                    } else { selectPermission(selectedPermission.concat(permission)) }
                                }} />} label={permission.permission} />
                        )
                    })}
                </div>
                <div>
                    <Button variant="contained" onClick={async () => await submit()}>更新</Button>
                </div>
            </Box></Box>
    )
}