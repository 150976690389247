import { useMemo, useState } from "react";
import { Box, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";

export default function AvailableCategory(props: {
    name: string;
    list: { id: string; category: string; brand: string; name: string; price: number; inStock: boolean }[];
    selectedComponent: { id: string; category: string; brand: string; name: string; price: number; inStock: boolean }[];
    onClickComponent: (id: string, category: string, brand: string, name: string, price: number, inStock: boolean) => void;
}) {
    const [filter, setFilter] = useState("");
    const filtered = useMemo(() => {
        return props.list.filter((c) => (c.brand.toUpperCase() + ' ' + c.name.toUpperCase()).includes(filter.toUpperCase()))
    }, [filter, props.list]);
    return (
        <>
            <ListItemButton>
                <ListItemText primary={props.name} />
            </ListItemButton>
            <Box>
                <TextField fullWidth label="Filter" variant="standard" value={filter} onChange={(e) => setFilter(e.currentTarget.value)} />
            </Box>
            <List sx={{ overflowY: "scroll", height: "100vh" }}>
                {filtered.map((product, i) => {
                    return (
                        <ListItem key={i}
                            onClick={() => props.onClickComponent(product.id, product.category, product.brand, product.name, product.price, product.inStock)}>
                            <ListItemIcon>
                                <Checkbox checked={props.selectedComponent.some((c) => c.id === product.id)} />
                            </ListItemIcon>
                            <ListItemText id={product.id} primary={product.brand + ' ' + product.name + ' - $' + product.price} />
                        </ListItem>
                    )
                })}
            </List>
        </>
    );
};