import { forwardRef, useEffect, useState } from "react";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import CustomerModalIndex from "./Modal/Index";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200 },
    {
        field: 'name',
        headerName: '姓名',
        width: 250,
        editable: false,
    },
    {
        field: "Email",
        headerName: "電郵",
        width: 200,
        editable: false,
    },
    {
        field: "credit",
        headerName: "積分",
        type: "number",
        width: 100,
        editable: false,
    },];

export default function CustomerIndex() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<{ id: string; name: string; email: string; credit: number; }[]>([]);
    const [userID, setUserID] = useState("");
    const ModelComponent = forwardRef(() => <CustomerModalIndex id={userID} />);
    useEffect(() => {
        ; (async () => {
            setLoading(true);
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/customer/all`);
            const json = await result.json();
            setData(json.result);
            setLoading(false);
        })()
    }, []);
    return (
        <div>
            {loading ? <Stack spacing={1}>
                <Skeleton variant="rectangular" width={800} height={60} animation="wave" />
                <Skeleton variant="rectangular" width={800} height={1100} animation="wave" />
            </Stack> :
                <Box>
                    <DataGrid rows={data} columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 50 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableRowSelectionOnClick
                        onCellClick={(e) => setUserID(e.id + "")}
                    /></Box>}
            <Modal open={userID !== ""} onClose={() => setUserID("")}><ModelComponent /></Modal>
        </div>
    );
};