import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";

export default function ProductListContainer(props: {
    list: { id: string; name: string; partNumber: string; }[]
    selectItem: (id: string, name: string) => void;
}) {
    const [filter, setFilter] = useState("");
    const filtered = useMemo(() => {
        return props.list.filter((c) => (c.name.toUpperCase()).includes(filter.toUpperCase()))
    }, [filter, props.list]);
    return (
        <Box sx={{ margin: 2 }}>
            <TextField type="search" label="篩選" value={filter} onChange={(e) => setFilter(e.currentTarget.value)} />
            <Box sx={{ height: 400, overflow: 'scroll' }}>
                {filtered.map((product, i) => {
                    return (
                        <div key={i} style={{ cursor: "pointer" }} onClick={() => props.selectItem(product.id, product.name)}>{product.name}</div>
                    )
                })}
            </Box>
        </Box>
    )
}