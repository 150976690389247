import { forwardRef, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';

import BundleModalIndex from './Modal/Index';
import { Button } from '@mui/material';

export default function BundleIndex() {
    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", width: 90, editable: false },
        { field: "chineseName", headerName: "中文名", width: 150, editable: false },
        { field: "price", headerClassName: "價錢", type: "number", width: 100, editable: false }
    ];
    const [rows, setRows] = useState<{ id: string; chineseName: string; price: number; }[]>([
        { id: "1", chineseName: '中名', price: 0 },
        { id: "2", chineseName: '中名', price: 0 },
        { id: "3", chineseName: '中名', price: 0 },
        { id: "4", chineseName: '中名', price: 0 },
        { id: "5", chineseName: '中名', price: 0 },
        { id: "6", chineseName: '中名', price: 0 },
        { id: "7", chineseName: '中名', price: 0 },
        { id: "8", chineseName: '中名', price: 0 },
        { id: "9", chineseName: '中名', price: 0 },
        { id: "10", chineseName: '中名', price: 0 }
    ]);
    const [modal, setModal] = useState("");
    const ModelComponent = forwardRef(() => <BundleModalIndex id={modal} closeModal={() => setModal("")} />);
    useEffect(() => {
        ; (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/bundle/all`);
            const json = await result.json();
            setRows(json.result);
        })()
    }, []);
    return (
        <>
            <DataGrid rows={rows} columns={columns} disableRowSelectionOnClick pageSizeOptions={[5, 10]}
                initialState={{ pagination: { paginationModel: { page: 0, pageSize: 50 } } }}
                onCellClick={(e) => setModal(e.id + "")} />
            <Button onClick={() => setModal("new")}>{"新增"}</Button>
            <Modal open={modal !== ""} onClose={() => setModal("")}><ModelComponent /></Modal>
        </>
    );
};