import { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListSubheader from "@mui/material/ListSubheader";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

function not(a: readonly { id: string; path: string; width: number; height: number; }[],
    b: readonly { id: string; path: string; width: number; height: number; }[]) {
    return a.filter((value) => b.indexOf(value) === -1);
};

function intersection(a: readonly { id: string; path: string; width: number; height: number; }[],
    b: readonly { id: string; path: string; width: number; height: number; }[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
};
export default function ImageContainer(props: {
    left: { id: string; path: string; width: number; height: number; }[];
    setLeft: (list: { id: string; path: string; width: number; height: number; }[]) => void;
    right: { id: string; path: string; width: number; height: number; }[];
    setRight: (list: { id: string; path: string; width: number; height: number; }[]) => void;
}) {
    const [checked, setChecked] = useState<readonly { id: string; path: string; width: number; height: number; }[]>([]);
    // const [left, setLeft] = useState<readonly { id: string; path: string; width: number; height: number; }[]>(props.used);
    // const [right, setRight] = useState<readonly { id: string; path: string; width: number; height: number; }[]>(props.unused);

    const leftChecked = intersection(checked, props.left);
    const rightChecked = intersection(checked, props.right);
    const handleToggle = (item: { id: string; path: string; width: number; height: number; }) => () => {
        const currentIndex = checked.findIndex((image) => image.id === item.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        props.setRight(props.right.concat(props.left));
        props.setLeft([]);
    };

    const handleCheckedRight = () => {
        props.setRight(props.right.concat(leftChecked));
        props.setLeft(not(props.left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        props.setLeft(props.left.concat(rightChecked));
        props.setRight(not(props.right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        props.setLeft(props.left.concat(props.right));
        props.setRight([]);
    };

    const customList = (title: string, items: readonly { id: string; path: string; width: number; height: number; }[]) => (
        <Paper sx={{ width: 200, maxHeight: "75vh", overflow: 'auto' }}>
            <ListSubheader>{title}</ListSubheader>
            <List dense component="div" role="list">
                {items.map((image, i) => {
                    // const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem
                            key={i}
                            role="listitem"
                            button
                            onClick={handleToggle(image)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.findIndex((c) => c.id === image.id) !== -1}
                                    tabIndex={-1} disableRipple
                                    inputProps={{ 'aria-labelledby': image.id, }}
                                />
                            </ListItemIcon>
                            <div style={{ width: "200px", height: "auto" }}>
                                <img src={image.path} width={image.width} height={image.height} alt="" style={{ width: "100%", height: "100%" }} />
                            </div>
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
    return (
        <Grid container spacing={2} justifyContent="center" >
            <Grid item>{customList("正在使用", props.left)}</Grid>
            <Grid item display="flex" alignItems="center">
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={props.left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={props.right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList("未使用", props.right)}</Grid>
        </Grid>
    )
}