import { Box, Button, Modal } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { forwardRef, useEffect, useState } from "react";
import PromoCodeModalIndex from "./Modal/Index";

const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 120 },
    { field: "name", headerName: "名稱", width: 400 },
    // { field: "usage", headerName: "使用情況", width: 200 },
    { field: "expired", headerName: "過期", width: 100, type: "boolean" }
];

export default function PromoCodeIndex() {
    const [code, setCode] = useState("");
    const [rows, setRows] = useState<any[]>([])
    const ModelComponent = forwardRef(() => <PromoCodeModalIndex id={code} close={(id) => setCode(id + "")} />);
    useEffect(() => {
        (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/promo-code/all`);
            const json = await result.json();
            setRows(json.promoCodeList);
        })()
    }, [])
    return (
        <>
            <Box>
                <Box>
                    <Button onClick={() => setCode("new")}>{"新增"}</Button>
                </Box>
                <DataGrid rows={rows} columns={columns} disableRowSelectionOnClick
                    onCellClick={(e) => setCode(e.id + "")} />
            </Box>
            <Modal open={code !== ""} onClose={() => setCode("")}><ModelComponent /></Modal>
        </>
    )
}