import { useNavigate } from "react-router-dom";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export default function CategoryGroup(props: {
    name: string;
    categoryList: { id: number; name: string; image: { path: string; width: number; height: number; }; }[];
}) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    return (
        <>
            <ListItemButton onClick={() => setOpen(!open)}>
                <ListItemIcon>
                    {/* <ShoppingCartIcon /> */}
                </ListItemIcon>
                <ListItemText primary={props.name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {props.categoryList.map((category, j) => {
                        return (
                            <ListItemButton sx={{ pl: 4 }} key={j} onClick={() => navigate("/category/" + category.id)}>
                                <ListItemIcon>
                                    {/* <ShoppingCartIcon /> */}
                                </ListItemIcon>
                                <ListItemText primary={category.name} />
                            </ListItemButton>
                        )
                    })}
                </List>
            </Collapse>
        </>
    )
}