import { forwardRef, useEffect, useState } from "react";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import PcSetModalIndex from "./Modal/Index";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 120 },
    {
        field: 'name',
        headerName: '名稱',
        width: 200,
        editable: false,
    },
    {
        field: "price",
        headerName: "加/減價",
        type: "number",
        width: 100,
        editable: false,
    },
    {
        field: "specialPrice",
        headerName: "最低價格",
        type: "number",
        width: 100,
        editable: false,
    },
    {
        field: "available",
        headerName: "網店顯示",
        type: "boolean",
        width: 130,
        editable: false,
    }
    // {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params: GridValueGetterParams) =>
    //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
];

export default function PcSetIndex() {
    const [pcSetList, setPcSetList] = useState<{ id: string; name: string; price: number; specialPrice: number; available: boolean; }[]>([]);
    const [modal, setModal] = useState("");
    const ModalComponent = forwardRef(() => <PcSetModalIndex id={modal} close={() => setModal("")} />);
    useEffect(() => {
        (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/pc-set/all`);
            const json = await result.json();
            setPcSetList(json.result);
        })()
    }, []);
    return (
        <>
            <DataGrid rows={pcSetList} columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 50 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                disableRowSelectionOnClick
                onCellClick={(e) => setModal(e.id + "")}
            />
            <div>
                <div onClick={() => setModal("new")}>新增</div>
            </div>
            <Modal open={modal !== ""} onClose={() => setModal("")}><ModalComponent /></Modal>
        </>
    )
}