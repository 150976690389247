import { Container, Grid, Paper } from "@mui/material";
import HomeCharts from "./Charts";

export default function HomeIndex() {
    return (
        <Container>
            {/* <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={9}>
                    <Paper sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 240
                    }}>
                        <HomeCharts />
                    </Paper>
                </Grid>
            </Grid> */}
        </Container>
    )
}