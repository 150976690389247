import { Box, TextField, InputAdornment, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, IconButton, Button } from "@mui/material";
import { useEffect, useState } from "react";
import ProductListContainer from "./ProductListContainer";
import CloseIcon from '@mui/icons-material/Close';

export default function PromoCodeModalIndex(props: { id: string; close: (id: number) => void; }) {
    const [loading, setLoading] = useState(false);
    const [chineseName, setChineseName] = useState("");
    const [englishName, setEnglishName] = useState("");
    const [billMinPrice, setBillMinPrice] = useState(0);
    const [promoCodeAmount, setPromoCodeAmount] = useState(1);
    const [code, setCode] = useState("");
    const [type, setType] = useState("");
    const [discount, setDiscount] = useState({ type: "", number: 0 });
    const [allProductList, setAllProductList] = useState<{ id: string; partNumber: string; name: string; }[]>([]);
    const [expiredDate, setExpiredDate] = useState(new Date().getFullYear() + "/" + new Date().getMonth() + "/" + new Date().getDay())
    const [product, setProduct] = useState({ id: "", name: "" });
    const [condition, setCondition] = useState(false);
    const [conditionProduct, setConditionProduct] = useState<{ id: string; name: string; }[]>([]);
    useEffect(() => {
        setLoading(true);
        (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/promo-code/initial`);
            const json = await result.json();
            setAllProductList(json.productList);
        })()
        if (props.id !== "new") { }
    }, [props.id]);
    async function onClickAddPromoCode() {
        if (promoCodeAmount < 0) { return alert('Promo Code 數量不能為負數'); };
        if (discount.type === "percent") { if (discount.number > 100) { return alert("減價百分比不能大於100"); }; };
        setLoading(true);
        const conditionProductList: { id: string; amount: number; }[] = [];
        for (const product of conditionProduct) {
            if (conditionProductList.length === 0) {
                conditionProductList.push({ id: product.id, amount: 1 });
            } else {
                for (let i = 0; i < conditionProductList.length; i++) {
                    if (conditionProductList[i].id === product.id) {
                        conditionProductList[i].amount += 1;
                    } else if (i === conditionProductList.length - 1) {
                        conditionProductList.push({ id: product.id, amount: 1 });
                    }
                };
            };
        };
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/promo-code`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }, body: JSON.stringify({ chineseName, englishName, billMinPrice, promoCodeAmount, expiredDate, code, discount, product, conditionProduct: conditionProductList })
        });
        const json = await result.json();
        if (json.result === 'success') { props.close(json.id) } else { setLoading(false); };
    };
    return (
        <Box sx={{
            position: "absolute" as "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: "50px",
            maxWidth: "750px",
            width: "80vw",
            maxHeight: "90vh",
            overflowY: "scroll",
            boxShadow: 24,
            bgcolor: 'background.paper'
        }}>
            <Box>
                <TextField sx={{ margin: 2 }} label="中文名稱" value={chineseName} onChange={(e) => setChineseName(e.currentTarget.value)} />
                <TextField sx={{ margin: 2 }} label="英文名稱" value={englishName} onChange={(e) => setEnglishName(e.currentTarget.value)} />
                <TextField sx={{ margin: 2 }} type="date" label="到期日" value={expiredDate} helperText="2020/01/01等同於 踏入2020年PromoCode即失效"
                    onChange={(e) => setExpiredDate(e.currentTarget.value)} />
            </Box>
            <Box><TextField sx={{ margin: 2 }} label="使用時訂單最小價格" helperText="0代表無需特定消費額" value={billMinPrice}
                InputProps={{ startAdornment: (<InputAdornment position="start">$</InputAdornment>), }}
                onChange={(e) => setBillMinPrice(+e.currentTarget.value)}
            /></Box>
            <Box>
                <FormControlLabel sx={{ margin: 2 }} control={<Checkbox />} label="限期前無限重用" checked={promoCodeAmount === 0}
                    onChange={() => { if (promoCodeAmount === 0) { setPromoCodeAmount(1) } else { setPromoCodeAmount(0) } }} />
                {promoCodeAmount !== 0 && <TextField sx={{ margin: 2 }} label="PromoCode數量" type="number" value={promoCodeAmount} onChange={(e) => setPromoCodeAmount(+e.currentTarget.value)} />}
            </Box>
            {promoCodeAmount === 0 && <Box><TextField sx={{ margin: 2 }} label="Promo Code" value={code} onChange={(e) => setCode(e.currentTarget.value)} /></Box>}
            <Box sx={{ margin: 2 }}>
                <InputLabel>類別</InputLabel>
                <Select value={type} onChange={(e) => { setType(e.target.value); if (e.target.value === "product") { setDiscount({ type: "amount", number: 0 }) } }}>
                    <MenuItem value={"bill"}>{'全單減價'}</MenuItem>
                    <MenuItem value={'product'}>{"貨品減價"}</MenuItem>
                </Select>
            </Box>
            {type === "bill" && <>
                <Box sx={{ margin: 2 }}>
                    <InputLabel>減價類型</InputLabel>
                    <Select value={discount.type} onChange={(e) => setDiscount({ type: e.target.value, number: 0 })}>
                        <MenuItem value={"percent"}>{'百份比'}</MenuItem>
                        <MenuItem value={'amount'}>{"特定金額"}</MenuItem>
                    </Select>
                </Box>
                {discount.type === "percent" && <Box>
                    <Box><TextField sx={{ margin: 2 }} label="折扣" type="number" helperText="10%off等同九折" value={discount.number}
                        InputProps={{ endAdornment: (<InputAdornment position="end" >% off</InputAdornment>) }}
                        onChange={(e) => setDiscount({ ...discount, number: +e.currentTarget.value })}
                    /></Box>
                </Box>}
                {discount.type === "amount" && <Box><TextField sx={{ margin: 2 }} label="折扣" type="number" helperText="" value={discount.number}
                    InputProps={{ startAdornment: (<InputAdornment position="start">- $</InputAdornment>) }}
                    onChange={(e) => setDiscount({ ...discount, number: +e.currentTarget.value })}
                /></Box>}
            </>}
            {type === "product" &&
                <>
                    <Box>
                        <FormControlLabel sx={{ margin: 2 }} control={<Checkbox />} label="免費"
                            checked={discount.type === "percent" && discount.number === 100}
                            onChange={() => {
                                if (discount.type === "percent" && discount.number === 100) {
                                    setDiscount({ type: "amount", number: 0 })
                                } else { setDiscount({ type: "percent", number: 100 }) }
                            }} />
                        {(discount.type !== "percent" && discount.number !== 100) && <TextField sx={{ margin: 2 }} label="折扣" helperText="" value={discount.number}
                            InputProps={{ startAdornment: (<InputAdornment position="start">- $</InputAdornment>) }}
                            onChange={(e) => setDiscount({ ...discount, number: +e.currentTarget.value })}
                        />}
                    </Box>
                    {product.id !== "" ?
                        <Box sx={{ margin: 2 }}>
                            {'已選擇： '}
                            <IconButton onClick={() => setProduct({ id: "", name: "" })}><CloseIcon /></IconButton>
                            {product.name}
                        </Box> :
                        <ProductListContainer list={allProductList} selectItem={(id: string, name: string) => setProduct({ id, name })} />}
                </>}
            <hr />
            <FormControlLabel sx={{ margin: 2 }} control={<Checkbox />} label="使用Promo Code時訂單須含有特定商品"
                checked={condition} onChange={() => setCondition(!condition)} />
            {condition && <Box>
                <Box>
                    <Box>{'須包含以下商品：'}</Box>
                    <Box sx={{ maxHeight: 180, overflow: "scroll" }}>
                        {conditionProduct.map((product, i) => {
                            return (
                                <Box key={i}>
                                    <IconButton onClick={() => setConditionProduct(conditionProduct.filter((p) => p.id !== product.id))}>
                                        <CloseIcon /></IconButton>{product.name}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                <ProductListContainer list={allProductList} selectItem={(id, name) => setConditionProduct(conditionProduct.concat({ id, name }))} />
            </Box>}
            <Box><Button onClick={async () => await onClickAddPromoCode()}>{'提交'}</Button></Box>
        </Box>
    )
}