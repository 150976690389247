import { FormControlLabel, Switch, TextField, Box, Button } from "@mui/material";
import { useState } from "react";

export default function ModalCategory(props: {
    data: { inuse: boolean; id: string; chineseName: string; englishName: string; link: string; };
    closeModal: () => Promise<void>;
}) {
    const [loading, setLoading] = useState(false);
    const [inuse, setInuse] = useState(props.data.inuse);
    const [chineseName, setChineseName] = useState(props.data.chineseName);
    const [englishName, setEnglishName] = useState(props.data.englishName);
    const [link, setLink] = useState(props.data.link);
    const [file, setFile] = useState<{ image: string | File }>({ image: "" });
    const updateCategory = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('id', props.data.id);
        formData.append('inuse', inuse ? '1' : '0');
        formData.append('chineseName', chineseName);
        formData.append('englishName', englishName);
        formData.append('link', link);
        formData.append('image', file.image);
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/category`, {
            method: "PUT", body: formData
        });
        const json = await result.json();
        if (json.result === 'success') { setLoading(false); props.closeModal(); };
    };
    return (
        <>
            <FormControlLabel sx={{ margin: 1 }} control={<Switch checked={inuse} onChange={() => setInuse(!inuse)} />} label="啟用" />
            <TextField sx={{ margin: 1 }} fullWidth label="中文名稱" value={chineseName} onChange={(e) => setChineseName(e.currentTarget.value)} />
            <TextField sx={{ margin: 1 }} fullWidth label="英文名稱" value={englishName} onChange={(e) => setEnglishName(e.currentTarget.value)} />
            <TextField sx={{ margin: 1 }} fullWidth label="網址" value={link} onChange={(e) => setLink(e.currentTarget.value)} />
            <Box sx={{ margin: 1 }}>
                <label htmlFor="image">圖片：</label>
                <input type="file" name="image" id="image" onChange={(e) => {
                    if (e.currentTarget.files?.length) { setFile({ image: e.currentTarget.files[0] }) }
                }} />
            </Box>
            <Button variant="contained" onClick={async () => await updateCategory()}>{loading ? "" : "提交"}</Button>
        </>
    )
}