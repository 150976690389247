import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListSubheader from "@mui/material/ListSubheader";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

function not(a: readonly { id: string; path: string; }[],
    b: readonly { id: string; path: string; }[]) {
    return a.filter((value) => b.indexOf(value) === -1);
};

function intersection(a: readonly { id: string; path: string; }[],
    b: readonly { id: string; path: string; }[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
};

export default function IndexVideo() {
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [video, setVideo] = useState<{ video: string | File }>({ video: "" });
    const [youtube, setYoutube] = useState("");
    const [checked, setChecked] = useState<readonly { id: string; path: string; }[]>([]);
    const [left, setLeft] = useState<readonly { id: string; path: string; }[]>([]);
    const [right, setRight] = useState<readonly { id: string; path: string; }[]>([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const handleToggle = (item: { id: string; path: string; }) => () => {
        const currentIndex = checked.findIndex((image) => image.id === item.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (title: string, items: readonly { id: string; path: string; }[]) => (
        <Paper sx={{ width: 400, maxHeight: "75vh", overflow: 'auto' }}>
            <ListSubheader>{title}</ListSubheader>
            <List dense component="div" role="list">
                {items.map((video, i) => {
                    // const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem key={i} role="listitem" button onClick={handleToggle(video)} >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.findIndex((c) => c.id === video.id) !== -1}
                                    tabIndex={-1} disableRipple
                                    inputProps={{ 'aria-labelledby': video.id, }}
                                />
                            </ListItemIcon>
                            <div>
                                <video style={{ width: "200px", height: "100px" }} playsInline muted autoPlay disableRemotePlayback disablePictureInPicture={true}
                                    src={process.env.REACT_APP_ASSET_URL + video.path} />
                            </div>
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
    useEffect(() => {
        setLoading(true);
        ; (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/index-video`);
            const json = await result.json();
            setLeft(json.result.list.filter((v: any) => v.inUse === false))
            setRight(json.result.list.filter((v: any) => v.inUse === true).sort((a: any, b: any) => a.sequence - b.sequence))
        })()
        setLoading(false);
    }, [update]);
    async function updateVideo() {
        setLoading(true);
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/index-video`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }, body: JSON.stringify({ list: right })
        });
        if (result.status === 200) { setUpdate(!update); };
    }
    async function uploadVideo() {
        setLoading(true);
        const formData = new FormData();
        formData.append('video', video.video);
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/index-video`, {
            method: "POST", body: formData
        });
        if (result.status === 200) { setUpdate(!update); } else { }
    };
    async function uploadYoutube() {
        setLoading(true);
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/index-video/youtube`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }, body: JSON.stringify({ link: youtube })
        });
        if (result.status === 200) { setUpdate(!update); };
    };
    return (
        <div>
            {loading ? <div>loading</div> : <>
                <div>
                    <Grid container spacing={2} justifyContent="center" >
                        <Grid item>{customList("未使用", left)}</Grid>
                        <Grid item display="flex" alignItems="center">
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList("正在使用", right)}</Grid>
                    </Grid>
                    <Button variant="contained" onClick={async () => await updateVideo()} >提交</Button>
                </div>
                <hr />
                <div>
                    <label htmlFor="video">{"短片："}</label>
                    <input type="file" name="video" id="video" onChange={(e) => {
                        if (e.currentTarget.files?.length) { setVideo({ video: e.currentTarget.files[0] }) }
                    }} />
                    <button type="submit" onClick={async () => await uploadVideo()}>提交</button>
                </div>
                <div>
                    <label htmlFor="youtube">{"Youtube: "}</label>
                    <input type="text" name="youtube" id="youtube" value={youtube} onChange={(e) => setYoutube(e.currentTarget.value)} />
                    <button type="submit" onClick={async () => await uploadYoutube()}>提交</button>
                </div>
            </>}
        </div>
    )
}