import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import CategoryIcon from '@mui/icons-material/Category';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LayersIcon from '@mui/icons-material/Layers';
import PeopleIcon from '@mui/icons-material/People';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import CodeIcon from '@mui/icons-material/Code';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryGroup from './CategoryGroup';
// import { socket } from "../socket";
import { useAppSelector } from "../hook";


const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function NavMenuBarIndex() {
    const navigate = useNavigate();
    // const [isConnected, setIsConnected] = useState(socket.connected);
    const routerLocation = useLocation();
    const permissionList = useAppSelector((state) => state.permission);
    const [open, setOpen] = useState(true);
    const [categoryGroup, setCategoryGroup] = useState<{
        name: string;
        category: { id: number; name: string; image: { path: string; width: number; height: number; }; }[];
    }[]>([]);
    const toggleDrawer = () => setOpen(!open);
    const path = routerLocation.pathname.split('/');
    const location = useMemo(() => {
        if (path.length === 3) {
            const categoryList: { id: number; group: string; name: string; }[] = [];
            for (const group of categoryGroup) {
                for (const category of group.category) {
                    categoryList.push({ id: category.id, group: group.name, name: category.name });
                };
            };
            const category = categoryList.filter((category) => category.id === +path[2]);
            if (category.length === 0) { return "Not Found" } else { return category[0].group + " - " + category[0].name };
        } else {
            switch (path[1]) {
                case "": return "主頁";
                case "order": return "訂單";
                case "category": return "產品";
                case "customer": return "顧客";
                case "pc-set": return "砌機套餐";
                case "bundle": return "組合商品";
                case "category-setting": return "分類設定";
                case "carousel": return "Carousel";
                case "promotion": return "宣傳頁面";
                case "promo-code": return "Promo Code";
                case "sf-express": return "順豐";
                case "index-video": return "首頁影片";
                default: return "新頁";
            };
        };
    }, [path, categoryGroup]);
    useEffect(() => {
        ; (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/initial`);
            const json = await result.json();
            setCategoryGroup(json.result);
        })()
    }, []);
    // useEffect(() => {
    //     function onConnect() {
    //         setIsConnected(true);
    //     }

    //     function onDisconnect() {
    //         setIsConnected(false);
    //     }

    //     socket.on('connect', onConnect);
    //     socket.on('disconnect', onDisconnect);

    //     return () => {
    //         socket.off('connect', onConnect);
    //         socket.off('disconnect', onDisconnect);
    //     };
    // }, []);
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar sx={{
                    pr: '24px', // keep right padding when drawer closed
                }} >
                    <IconButton edge="start" color="inherit" aria-label="open drawer"
                        onClick={toggleDrawer} sx={{ marginRight: '36px', ...(open && { display: 'none' }), }} >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}   >
                        {location}
                    </Typography>
                    {/* <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} style={{ height: '100vh', overflowY: "scroll" }}>
                <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1], }} >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <ListItemButton onClick={() => navigate("/")}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="主頁" />
                    </ListItemButton>
                    <ListItemButton onClick={() => navigate("/order")}>
                        <ListItemIcon>
                            <ShoppingCartIcon />
                        </ListItemIcon>
                        <ListItemText primary="訂單" />
                    </ListItemButton>
                    {permissionList.includes("分類設定") && <><Divider sx={{ my: 1 }} />
                        <ListSubheader component="div" inset>
                            分類
                        </ListSubheader>
                        <ListItemButton onClick={() => navigate("/category-setting")}>
                            <ListItemIcon>
                                <CategoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="分類設定" />
                        </ListItemButton></>}
                    {permissionList.includes("顧客") && <>
                        <Divider sx={{ my: 1 }} /><ListSubheader component="div" inset>
                            顧客
                        </ListSubheader>
                        <ListItemButton onClick={() => navigate("/customer")}>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="顧客" />
                        </ListItemButton></>}
                    <Divider sx={{ my: 1 }} />
                    <ListSubheader component="div" inset>{'商品'}</ListSubheader>
                    {categoryGroup.map((group, i) => { return (<CategoryGroup key={i} name={group.name} categoryList={group.category} />) })}
                    <ListItemButton onClick={() => navigate("/pc-set")}>
                        <ListItemIcon>
                            <ShoppingCartIcon />
                        </ListItemIcon>
                        <ListItemText primary="砌機套餐" />
                    </ListItemButton>
                    <ListItemButton onClick={() => navigate("/bundle")}>
                        <ListItemIcon>
                            <LayersIcon />
                        </ListItemIcon>
                        <ListItemText primary="組合商品" />
                    </ListItemButton>
                    {/* <Divider sx={{ my: 1 }} />
                    <ListSubheader component="div" inset>
                        推銷
                    </ListSubheader> */}
                    {/* <ListItemButton onClick={() => navigate("/promotion")}>
                        <ListItemIcon>
                            <ViewCarouselIcon />
                        </ListItemIcon>
                        <ListItemText primary="宣傳頁面" />
                    </ListItemButton> */}
                    {permissionList.includes("Promo Code") && <ListItemButton onClick={() => navigate("/promo-code")}>
                        <ListItemIcon>
                            <CodeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Promo Code" />
                    </ListItemButton>}
                    <Divider sx={{ my: 1 }} />
                    <ListSubheader component="div" inset>
                        版面設定
                    </ListSubheader>
                    <ListItemButton onClick={() => navigate("/carousel")}>
                        <ListItemIcon>
                            <ViewCarouselIcon />
                        </ListItemIcon>
                        <ListItemText primary="Carousel" />
                    </ListItemButton>
                    <ListItemButton onClick={() => navigate("/index-video")}>
                        <ListItemIcon>
                            <YouTubeIcon />
                        </ListItemIcon>
                        <ListItemText primary="首頁影片" />
                    </ListItemButton>
                    <Divider sx={{ my: 1 }} />
                    {permissionList.includes("員工帳戶") && <ListItemButton onClick={() => navigate("/account")}>
                        <ListItemIcon>
                            <ViewCarouselIcon />
                        </ListItemIcon>
                        <ListItemText primary="員工帳戶" />
                    </ListItemButton>}
                    <ListItemButton onClick={() => navigate("/sf-express")}>
                        <ListItemIcon>
                            <LocalShippingIcon />
                        </ListItemIcon>
                        <ListItemText primary="順豐" />
                    </ListItemButton>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
            </Box>
        </Box>
    );
}