import { Fragment, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';

export default function ModalIndex(props: { id: string; onClose: () => void; }) {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [posID, setPosID] = useState("");
    interface Image { image: string | File };
    const [file, setFile] = useState<Image>({ image: '' });
    const [data, setData] = useState<null | {
        id: string; posID: null | string; price: number; userID: string;
        promoCode?: { code: string; name: string; discountPercent: number | null; discountAmount: number | null; productName: string | null; };
        itemList: { partNumber: string; brand: string; name: string; price: number; amount: number; }[];
        freeDelivery: boolean; paymentMethod: string; pickupMethod: string;
        status: string; recipient: { name: string; tel: string; address: string; email: string; };
        proveList: string[]; createdAT: Date; customerRemark: string; shopRemark: string;
    }>(null);
    const copy = async (data: string) => {
        let partNumber = data;
        while (partNumber.slice(-1) === ' ') { partNumber = partNumber.slice(0, -1); };
        await navigator.clipboard.writeText(partNumber);
    };
    useEffect(() => {
        (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/bill/${props.id}`);
            const json = await result.json();
            setData({ ...json.result, createdAT: new Date(json.result.createdAT) });
        })()
    }, [props.id]);
    async function onClickUpdateStatus() {
        setLoading(true);
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/bill/${props.id}/${status}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }, body: JSON.stringify({ posID })
        });
        if (result.status === 200) { setLoading(false); props.onClose(); };
    };
    async function fetchAddBillImage(file: string | File) {
        const formData = new FormData();
        formData.append('image', file);
        if (data) {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/bill-prove/${props.id}/${data.proveList.length + 1}`, {
                method: 'POST',
                body: formData
            });
            props.onClose();
        };
    };
    return (
        <Box sx={{
            position: "absolute" as "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: "50px",
            maxWidth: "800px",
            width: "80vw",
            maxHeight: "90vh",
            overflowY: "scroll",
            boxShadow: 24,
            bgcolor: 'background.paper'
        }}>
            {data ?
                <div>
                    <div>
                        <div>{`訂單編號：${data.id}`}</div>
                        {(data.status !== '未付款' && data.status !== '已取消') && (data.posID ? <div>{`POS單號：${data.posID}`}</div> :
                            <div><label htmlFor="pos">{"POS單號："}</label>
                                <input value={posID} onChange={(e) => setPosID(e.currentTarget.value)} /></div>)}
                        <div>{`訂單日期：${data.createdAT.toString()}`}</div>
                        <div>{`收件人：${data.recipient.name}`}</div>
                        <div>{`電郵：${data.recipient.email}`}</div>
                        <div>{`電話：${data.recipient.tel}`}
                            <a href={`https://api.whatsapp.com/send/?phone=852${data.recipient.tel}&app_absent=0`} target="_blank" rel="noreferrer">WHATSAPP</a>
                        </div>
                        <div>{`取件方式：${data.pickupMethod}`}</div>
                        {data.pickupMethod !== "鋪頭自取" && <div>{`地址：${data.recipient.address}`}</div>}
                        <div>{`付款方式：${data.paymentMethod === "FPS" ? "轉數快" : "信用卡"}`}</div>
                        {data.paymentMethod === "FPS" ? <div>
                            {data.proveList.map((prove, i) => {
                                return (
                                    <div key={i}>{"截圖 " + i + 1 + "："}<a target={'_blank'} rel="noreferrer" href={'https://adm.cap.com.hk/' + prove}>連結</a></div>
                                )
                            })}
                        </div> : <div>{"交易詳情："}<a href={`https://dashboard.stripe.com/payments/${data.proveList[0]}`} target="_blank" rel="noopener noreferrer">Stripe</a></div>}
                        {data.status !== "已取消" && <div>
                            <label htmlFor="prove">Prove:</label>
                            <input type="file" name="prove" id="prove" onChange={(e) => {
                                if (e.currentTarget.files?.length) { setFile({ image: e.currentTarget.files[0] }) }
                            }} />
                            <input type="submit" value="提交" onClick={(e) => { e.preventDefault(); fetchAddBillImage(file.image) }} />
                        </div>}
                        <div>{`顧客備註：${data.customerRemark}`}</div>
                    </div>
                    <div>
                        <table>
                            <caption>{"商品列表"}</caption>
                            <tbody>
                                <tr><th /><th>{"貨品"}</th><th>{"數量"}</th><th>{"價錢"}</th></tr>
                                {data.itemList.map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr >
                                                <td>{i + 1 + "."}</td>
                                                <td>{item.brand + " " + item.name}</td>
                                                <td>{item.amount}</td>
                                                <td>{'$ ' + item.price}</td>
                                            </tr>
                                            <tr><td colSpan={4} style={{ cursor: "pointer" }} onClick={() => copy(item.partNumber)}>{`Part No. : ${item.partNumber}`}</td></tr>
                                        </Fragment>
                                    )
                                })}
                                {data.promoCode && <>
                                    <tr>
                                        <td /><td colSpan={2}>{'已使用Promo Code：' + data.promoCode.name + "(" + data.promoCode.code + ")"}</td>
                                    </tr>
                                    <tr>
                                        <td />
                                        {data.promoCode.discountAmount &&
                                            <td>{(data.promoCode.productName ? data.promoCode.productName : "全單") + " 減 $" +
                                                data.promoCode.discountAmount}</td>}
                                        {data.promoCode.discountPercent &&
                                            <td>{(data.promoCode.productName ? data.promoCode.productName : "全單") + " " +
                                                (data.promoCode.discountPercent * 100) + "% off"}</td>}
                                    </tr>
                                </>}
                            </tbody>
                            <tfoot><tr>
                                <td colSpan={3}>{"總額："}</td><td>{'$ ' + data.price}</td>
                            </tr></tfoot>
                        </table>
                    </div>
                    {(data.status === "待確認" || data.status === "已確認" || data.status === "未付款") && <Box>
                        {(data.status === "待確認" || data.status === "未付款") && <Button variant="contained" onClick={() => setStatus("cancel")}>{"取消訂單"}</Button>}
                        {data.status !== "未付款" && <Button variant="contained" onClick={() => setStatus(data.status === "待確認" ? "confirm" : "finish")}>
                            {/* @ts-ignore */}
                            {data.status === "待確認" ? "確定訂單" : "完成訂單"}
                        </Button>}
                    </Box>}
                </div> :
                <div></div>}
            {data && <Backdrop open={status !== ""} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <div>
                    <div>{status === "cancel" ? "確定取消訂單？" : status === "confirm" ? "確定訂單？" : "訂單完成？"}</div>
                    <Box>
                        <Button onClick={() => setStatus("")}>{"取消"}</Button>
                        <Button variant="contained" onClick={async () => await onClickUpdateStatus()}>
                            {/* @ts-ignore */}
                            {data.status === "待確認" ? "確定訂單" : "完成訂單"}
                        </Button>
                    </Box>
                </div>
            </Backdrop>}
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress /></Backdrop>
        </Box>
    )
};