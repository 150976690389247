import { forwardRef, useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Modal } from "@mui/material";
import AccountModalIndex from "./Modal/Index";

const columns: GridColDef[] = [{ field: 'id', headerName: 'ID', width: 120 }, { field: "name", headerName: "名稱", width: 200 }];

export default function AccountIndex() {
    const [accountList, setAccountList] = useState<{ id: string; name: string; username: string; }[]>([]);
    const [account, setAccount] = useState("");
    const ModelComponent = forwardRef(() => <AccountModalIndex id={account} closeModal={() => setAccount("")} />);
    useEffect(() => {
        (async () => {
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/all-account`);
            const json = await result.json();
            setAccountList(json.list);
        })();
    }, []);
    return (
        <div>
            <Box>
                <DataGrid rows={accountList} columns={columns} onCellClick={(e) => setAccount(e.id + "")} />
                <div onClick={() => setAccount("new")}>New</div>
            </Box>
            <Modal open={account !== ""} onClose={() => setAccount("")}><ModelComponent /></Modal>
        </div>
    );
};