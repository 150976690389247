import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from "@mui/material/Switch";

export default function BasicInfo(props: {
    id: string; available: boolean; update: () => void;
    chineseName: string; setChineseName: (name: string) => void;
    englishName: string; setEnglishName: (name: string) => void;
    additionPrice: number; setAdditionPrice: (price: number) => void;
    onClose: () => void;
}) {
    async function switchStatus() {
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/pc-set/${props.id}/status`, { method: "PUT" });
        if (result.status === 200) { };
    };

    async function deletePcSet() {
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/pc-set/${props.id}`, { method: "DELETE" });
        await result.json();
        if (result.status === 200) { props.onClose(); };
    }
    return (
        <>
            <Box>
                <Switch checked={props.available} onChange={async () => await switchStatus()} />
                <Button variant="contained" onClick={async () => await deletePcSet()} >{'刪除'}</Button>
            </Box>
            <Box>
                <div><TextField sx={{ margin: 1 }} fullWidth label="名稱" value={props.chineseName} onChange={(e) => props.setChineseName(e.currentTarget.value)} /></div>
                <div><TextField sx={{ margin: 1 }} label="附加價" type="number" value={props.additionPrice} onChange={(e) => props.setAdditionPrice(+e.currentTarget.value)}
                    InputProps={{ startAdornment: (<InputAdornment position="start">$</InputAdornment>) }} /></div>
            </Box>
        </>
    );
};