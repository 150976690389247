import { useState, useEffect, forwardRef } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Box, Button, Modal } from "@mui/material";
import ModalCategory from "./Modal/Category";

export default function CategorySetting() {
    const [loading, setLoading] = useState(false);
    const [categoryGroupList, setCategoryGroupList] = useState<{
        id: string; chineseName: string; englishName: string; inUse: boolean;
        category: {
            id: string;
            posName: string;
            chineseName: string;
            englishName: string;
            link: string;
            imageLink: string;
            imageWidth: number;
            imageHeight: number;
            inuse: boolean;
        }[];
    }[]>([]);
    const onDragEnd = (event: DropResult) => {
        const { source, destination } = event;

        if (!destination) { return; }

        // 拷貝新的 items (來自 state) 
        let newCategoryGroup = [...categoryGroupList];

        const sourceCategoryGroupIndex = newCategoryGroup.findIndex((categoryGroup, i) => categoryGroup.id === source.droppableId);
        const destinationCategoryIndex = newCategoryGroup.findIndex((categoryGroup, i) => categoryGroup.id === destination.droppableId);
        if (sourceCategoryGroupIndex < 0 || destinationCategoryIndex < 0) { return; };
        // 用 splice 處理拖曳後資料, 組合出新的 items
        // splice(start, deleteCount, item )

        // 從 source.index 剪下被拖曳的元素
        // const [remove] = newCategoryGroup.filter((categoryGroup) => { })
        const [remove] = newCategoryGroup[sourceCategoryGroupIndex].category.splice(source.index, 1);

        //在 destination.index 位置貼上被拖曳的元素
        newCategoryGroup[destinationCategoryIndex].category.splice(destination.index, 0, remove);

        // 設定新的 items
        setCategoryGroupList(newCategoryGroup);
    };
    const [editCategory, setEditCategory] = useState<{ inuse: boolean; id: string; chineseName: string; englishName: string; link: string }>
        ({ inuse: false, id: '', chineseName: '', englishName: '', link: '' });
    const [editCategoryGroup, setEditCategoryGroup] = useState({ id: '', chineseName: '', englishName: '', });
    const CategoryModal = forwardRef(() => <ModalCategory data={editCategory} closeModal={async () => {
        setEditCategory({ inuse: false, id: '', chineseName: '', englishName: '', link: '' });
        setLoading(true); await loadData(); setLoading(false);
    }} />)
    useEffect(() => { setLoading(true); (async () => { await loadData(); })(); setLoading(false); }, []);

    const loadData = async () => {
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/category`);
        const json = await result.json();
        setCategoryGroupList(json);
    };

    const updateCategoryGroup = async () => {
        setLoading(true);
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/category-group`, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }, method: "PUT", body: JSON.stringify({ categoryGroupList })
        });
        const json = await result.json();
        if (json.result === 'success') { await loadData(); setEditCategoryGroup({ id: '', chineseName: '', englishName: '', }); setLoading(false); };
    };
    if (loading) { return <div>載入中...</div> }
    return (
        <>
            <Box>
                <Box sx={{ display: "flex", overflowX: "scroll", maxHeight: '80vh', marginBottom: "20px" }}>
                    <DragDropContext onDragEnd={(e) => onDragEnd(e)} >
                        {categoryGroupList.map((categoryGroup, i) => {
                            return (
                                <Box key={categoryGroup.id} sx={{ minWidth: 160, margin: "0px 10px 0px 10px" }}>
                                    <h3>{categoryGroup.chineseName}</h3>
                                    <Button variant="contained" onClick={() =>
                                        setEditCategoryGroup({ id: categoryGroup.id, chineseName: categoryGroup.chineseName, englishName: categoryGroup.englishName })}
                                    >{'修改'}</Button>
                                    <Droppable droppableId={categoryGroup.id}>
                                        {(provided, snapshot) => {
                                            return (
                                                <Box ref={provided.innerRef}  {...provided.droppableProps}>
                                                    {categoryGroup.category.map((category, j) => {
                                                        return (
                                                            <Draggable key={category.id} draggableId={category.id} index={j}>
                                                                {(provided, snapshot) => (
                                                                    <Box sx={{ padding: 2, margin: "2px 0px 2px 0px", border: 1 }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                        onClick={() =>
                                                                            setEditCategory({
                                                                                inuse: category.inuse,
                                                                                id: category.id,
                                                                                chineseName: category.chineseName,
                                                                                englishName: category.englishName,
                                                                                link: category.link
                                                                            })
                                                                        }>
                                                                        {/* 實際上的卡片內容 */}
                                                                        {category.chineseName + (category.inuse ? '' : ' （未啟用）')}
                                                                        {/* 實際上的卡片內容 */}
                                                                    </Box>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </Box>
                                            )
                                        }}
                                    </Droppable></Box>
                            )
                        })}
                    </DragDropContext></Box>
                <Button onClick={() => updateCategoryGroup()}>{"更新"}</Button>
            </Box>
            <Modal open={editCategory.id !== ''} onClose={() => setEditCategory({ inuse: false, id: '', chineseName: '', englishName: '', link: '' })}>
                <Box sx={{
                    position: "absolute" as "absolute",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: "50px",
                    width: "80vw",
                    maxWidth: "400px",
                    height: "90vh",
                    maxHeight: "600px",
                    boxShadow: 24,
                    bgcolor: 'background.paper'
                }} ><CategoryModal /></Box>
            </Modal>
            <Modal open={editCategoryGroup.id !== ''} onClose={() => setEditCategoryGroup({ id: '', chineseName: '', englishName: '' })}>
                <Box sx={{
                    position: "absolute" as "absolute",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: "flex",
                    padding: "50px",
                    width: "80vw",
                    maxWidth: "1300px",
                    height: "90vh",
                    maxHeight: "600px",
                    boxShadow: 24,
                    bgcolor: 'background.paper'
                }} />
            </Modal>
        </>
    )
};