import { useState } from "react";
import { Alert, Box, Button, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "./hook";
import LoggedIn from "./LoggedIn";
import CircularProgress from '@mui/material/CircularProgress';
import { login } from "./store";

export default function App() {
    const dispatch = useAppDispatch();
    const loggedIn = useAppSelector((state) => state.loggedIn);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [fail, setFail] = useState(false);
    async function pageLogin() {
        setFail(false);
        setLoading(true);
        const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }, body: JSON.stringify({ username, password })
        });
        const json = await result.json();
        if (json.result === 'success') { dispatch(login({ permission: json.permission })) } else { setFail(true); };
        setLoading(false);
    };
    // async function facebookLogin() {
    //     const result = await fetch('https://www.facebook.com/v18.0/dialog/oauth?client_id=' + "241065958943055" +
    //         `&redirect_uri=http://localhost:8080/api/admin/callback/facebook&state={st=state,ds=ds}`);
    // }
    return (
        <>
            {loggedIn ? <LoggedIn /> :
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
                    <Box>
                        <form onSubmit={async (e) => { e.preventDefault(); await pageLogin(); }}>
                            <TextField sx={{ marginTop: 2 }} autoFocus
                                fullWidth label="帳戶" value={username} onChange={(e) => setUsername(e.currentTarget.value)} />
                            <TextField sx={{ marginTop: 2 }}
                                fullWidth label="密碼" type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
                            <Box sx={{ marginTop: 2 }}>
                                <Button variant="contained" type="submit">{loading ? <CircularProgress /> : "登入"}</Button>
                            </Box>
                        </form>
                    </Box>
                    {fail && <Alert variant="filled" severity="error">{'Fail'}</Alert>}
                    {/* <Box>Or</Box> */}
                    {/* <Box><a href="https://www.facebook.com/v18.0/dialog/oauth?client_id=241065958943055&redirect_uri=http://localhost:8080/api/admin/callback/facebook&state={st=state,ds=ds}&scope=[pages_manage_engagement,]">Facebook</a></Box> */}
                </Box>
            }
        </>
    );
};