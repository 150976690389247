import { createSlice, configureStore, PayloadAction } from '@reduxjs/toolkit';

interface State {
    permission: string[];
    loggedIn: boolean,
    updateStatus: boolean,
    updateStatement: string[],
}

export const initialState: State = {
    permission: [],
    loggedIn: false,
    updateStatus: false,
    updateStatement: [],
}

const counterSlice = createSlice({
    name: 'page process',
    initialState,
    reducers: {
        // incremented: state => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1
        // },
        update: state => { state.updateStatus = !state.updateStatus },
        login: (state, action: PayloadAction<{ permission: string[]; }>) => {
            state.loggedIn = true; state.permission = action.payload.permission;
        },
        logout: state => { state.loggedIn = false; state.permission = []; }
    }
});

export const { update, login, logout } = counterSlice.actions

export const store = configureStore({ reducer: counterSlice.reducer });

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch