import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import { Button, ListItem, ListItemText, ListSubheader } from '@mui/material';

import AvailableCategory from './AvailableCategory';

export default function BundleModalIndex(props: { id: string; closeModal: () => void; }) {
    const categoryList = ["CPU - 中央處理器", "Motherboard - 底板", "Display Card - 顯示卡", "DESKTOP RAM -桌上電腦記憶體", '3.5" Hard Disk - 內置硬盤',
        "SSD - 固態硬盤", "POWER SUPPLY - 火牛", "Case - 機箱", "NOTEBOOK RAM - 手提電腦記憶體"];
    const [chineseName, setChineseName] = useState("");
    const [englishName, setEnglishName] = useState("");
    const [price, setPrice] = useState(0);
    const [images, setImages] = useState<any[]>([]);
    const [gif, setGif] = useState();
    const [allComponentList, setAllComponentList] = useState<{ id: string; category: string; brand: string; name: string; price: number; inStock: boolean; }[]>([]);
    const [selectedComponent, selectComponent] = useState<{ id: string; category: string; brand: string; name: string; price: number; inStock: boolean; }[]>([]);
    useEffect(() => {
        if (props.id === "new") {
            (async () => {
                const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/pc-set/initial`);
                const json = await result.json();
                setAllComponentList(json.allProduct.map((component: any) => {
                    return {
                        id: component.id, category: component.category, brand: component.brand, name: component.name, price: component.price,
                        inStock: component.in_stock === 1 ? true : false
                    };
                }));
            })();
        };
    }, [props.id]);
    async function onClickAddBundle() {
        if (props.id === "new") {
            let originalPrice = 0;
            const formData = new FormData();
            formData.append("chineseName", chineseName);
            formData.append("englishName", englishName);
            formData.append("price", price + "");
            for (const component of selectedComponent) {
                originalPrice += component.price;
                formData.append("itemList[]", component.id);
            };
            formData.append("originalPrice", originalPrice + "");
            const result = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : ''}/api/admin/bundle`, {
                method: "POST", body: formData
            });
            if (result.status === 200) { props.closeModal(); };
        }
    };
    return (
        <Box sx={{
            position: "absolute" as "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // display: "flex",
            padding: "50px",
            maxWidth: "1300px",
            width: "80vw",
            height: "90vh",
            boxShadow: 24,
            bgcolor: 'background.paper',
            overflow: 'scroll'
        }}>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "50%" }}>
                    <Box>
                        <div><TextField sx={{ margin: 1 }} fullWidth label="中文名稱" value={chineseName} onChange={(e) => setChineseName(e.currentTarget.value)} /></div>
                        <div><TextField sx={{ margin: 1 }} fullWidth label="英文名稱" value={englishName} onChange={(e) => setEnglishName(e.currentTarget.value)} /></div>
                        <div><TextField sx={{ margin: 1 }} label="價錢" type="number" value={price} onChange={(e) => setPrice(+e.currentTarget.value)}
                            InputProps={{ startAdornment: (<InputAdornment position="start">$</InputAdornment>) }} /></div>
                    </Box>
                    <Box>
                        <List sx={{ overflow: 'scroll', maxHeight: "50vh", '& ul': { padding: 0 }, }} subheader={<li />}>
                            {categoryList.map((category, i) => {
                                return (
                                    <li key={i}>
                                        <ul>
                                            <ListSubheader>{category}</ListSubheader>
                                            {selectedComponent.filter((c) => c.category === category).map((component, j) => {
                                                return (
                                                    <ListItem key={j}>
                                                        <ListItemText primary={component.brand + ' ' + component.name} />
                                                    </ListItem>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                )
                            })}
                        </List>
                    </Box>
                </Box>
                {props.id === "new" && <Box>
                    <List sx={{ overflowY: "scroll", height: "85vh" }}>
                        {categoryList.map((category, i) => {
                            return (
                                <AvailableCategory key={i} name={category} list={allComponentList.filter((p) => p.category === category)}
                                    onClickComponent={(id: string, category: string, brand: string, name: string, price: number, inStock: boolean) => {
                                        if (selectedComponent.some((c) => c.id === id)) {
                                            selectComponent(selectedComponent.filter((c) => c.id !== id));
                                        } else { selectComponent(selectedComponent.concat({ id, category, brand, name, price, inStock })) }
                                    }}
                                    selectedComponent={selectedComponent} />
                            )
                        })}
                    </List>
                </Box>}
            </Box>
            <Box><label htmlFor="image">圖片：</label>
                <input type="file" name="image" id="image" multiple onChange={(e) => {
                    if (e.currentTarget.files?.length) { setImages([...images, e.target.files]) }
                }} /></Box>
            <Box><Button type="submit" onClick={async () => await onClickAddBundle()}>{"提交"}</Button></Box>
        </Box>
    );
};